
.posterImage{
    height: 600px;
    
}

.posterImage > img {
    margin: auto;
    display: block;
    width: 100%;
}
.posterImage__overlay {
    position: absolute;
    padding: 5rem;
    bottom: 0px;
    height: 70%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: linear-gradient(rgb(0,0,0,0), rgb(0,0,0,1));
    opacity: 1;
    transition: opacity .3s;
}
.posterImage__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 4rem;
    margin-bottom: 0.4rem;
    text-align: left;
}
.posterImage__runtime {
    font-size: 2rem;
    margin-bottom: 1rem;
}
.posterImage__rating {
    margin-left: 2rem;
    margin-right: 2rem;
}
.posterImage__rating > i {
    margin-left: 0.4rem;
    
}
.posterImage__description {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: 1.2rem;
    margin-bottom: 0.25rem;
    display: flex;
    text-align: left;
    width: 50%;
}


@media screen and (max-width: 768px){
    .posterImage{
        height: 60%;
        
    }
    .posterImage__overlay {
        position: absolute;
        padding: 1rem;
        bottom: 0px;
        height: 10%;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-start;
        background-image: linear-gradient(rgb(0,0,0,0), rgb(0,0,0,1));
        opacity: 1;
        transition: opacity .3s;
    }


.posterImage__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0.1rem;
    text-align: left;
}
.posterImage__runtime {
    font-size: 0.5rem;
    margin-bottom: 1rem;
}
.posterImage__rating {
    margin-left: 1rem;
    margin-right: 1rem;
}
.posterImage__rating > i {
    margin-left: 0.1rem;
    
}
.posterImage__description {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: 0.5rem;
    margin-bottom: 0.25rem;
    display: flex;
    text-align: left;
    width: 100%;
    
}
}


@media only screen and (min-width: 769px) and (max-width: 1240px){
    .posterImage{
        height: 60%;
        
    }
    .posterImage__overlay {
        position: absolute;
        padding: 2rem;
        bottom: 0px;
        height: 20%;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-start;
        background-image: linear-gradient(rgb(0,0,0,0), rgb(0,0,0,1));
        opacity: 1;
        transition: opacity .3s;
    }


.posterImage__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 0.1rem;
    text-align: left;
}
.posterImage__runtime {
    font-size: 1rem;
    margin-bottom: 1rem;
}
.posterImage__rating {
    margin-left: 1rem;
    margin-right: 1rem;
}
.posterImage__rating > i {
    margin-left: 0.1rem;
    
}
.posterImage__description {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: 1rem;
    margin-bottom: 0.25rem;
    display: flex;
    text-align: left;
    width: 100%;
    
}
}
