.App {
  text-align: center;
  scroll-behavior: smooth;
}
/* width */
::-webkit-scrollbar {
  border-radius: 20px;
  width: 7px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #474747; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2c2c2c; 
}
/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.active{
  color: #ff505b;
  
}

@media (prefers-reduced-motion: no-preference) {
  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.active{
  
  background-color: #262627;
}
.popular:active{
  color: rgb(0, 0, 0);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
