.cards {
    display: inline-block;
    transition: transform .2s;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    margin: 0.5rem;
    cursor:pointer;
    min-width:200px;
    height:300px;
    z-index:0;
    border: 0.1px solid rgb(49, 49, 49);
}
.cards:hover {
    transform: scale(1.2);
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.cards__img {
    height: 300px;
}

.cards__overlay {
    position: absolute;
    padding: 0 1rem 1rem 1rem;
    bottom: 0px;
    height: 290px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    background-image: linear-gradient(rgb(0,0,0,0), rgb(0,0,0,1));
    opacity: 0;
    transition: 0.2s;
}
.cards__overlay:hover {
    opacity: 1;
}

.card__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 1rem;
    margin-bottom: 0.4rem;
}
.card__runtime {
    font-size: .75rem;
    margin-bottom: 0.25rem;
}
.card__rating {
    font-family: 'Montserrat', sans-serif;
    float: right;
}
.card__rating > i {
    margin-left: 0.2rem;
    
}
.card__description {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: .75rem;
    margin-bottom: 0.25rem;
}
.removeBtn {
    color: #e63844;
    z-index: 1000;
    min-width: 200px;
    position: absolute;
    top: -0.7rem;
    right: 0rem;
    /* padding: 0.2rem 5rem; */
    font-size: 2.5rem;
    cursor: pointer;
    transition: 0.2s;
    background-image: linear-gradient(rgb(0, 0, 0, 1), rgb(0,0,0,0));


}

.trash-icon{
    margin-left: 40%;
    margin-top: 1rem;

}